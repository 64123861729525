<template>
  <div>
    <v-text-field
      label="Number of years self employed"
      v-model="fields.number_of_years_self_employed"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('number_of_years_self_employed')"
      :error-messages="errors['number_of_years_self_employed']"
    ></v-text-field>

    <v-text-field
      label="Trading name (if not a sole trader)"
      v-model="fields.trading_name"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('trading_name')"
      :error-messages="errors['trading_name']"
    ></v-text-field>

    <v-text-field
      label="Last Financial years declared profit (if applicable)t"
      v-model="fields.last_financial_profit"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('last_financial_profit')"
      :error-messages="errors['last_financial_profit']"
    ></v-text-field>

    <v-text-field
      label="Current financial years expected profit"
      v-model="fields.current_financial_profit"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('current_financial_profit')"
      :error-messages="errors['current_financial_profit']"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        trading_name: null,
        number_of_years_self_employed: null,
        current_financial_profit: null,
        last_financial_profit: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.form !== null) {
      this.fields.trading_name = this.form.employment.employment.trading_name;
      this.fields.number_of_years_self_employed =
        this.form.employment.employment.number_of_years_self_employed;
      this.fields.current_financial_profit =
        this.form.employment.employment.current_financial_profit;
      this.fields.last_financial_profit =
        this.form.employment.employment.last_financial_profit;
    }
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
