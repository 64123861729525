<template>
  <div>
    <v-text-field
      label="Company Name"
      v-model="fields.company_name"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('company_name')"
      :error-messages="errors['company_name']"
    ></v-text-field>

    <v-text-field
      label="Contact Name"
      v-model="fields.contact_name"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('contact_name')"
      :error-messages="errors['contact_name']"
    ></v-text-field>

    <v-text-field
      label="Address Line 1"
      v-model="fields.address_line_1"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('address_line_1')"
      :error-messages="errors['address_line_1']"
    ></v-text-field>

    <v-text-field
      label="Address Line 2"
      v-model="fields.address_line_2"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('address_line_2')"
      :error-messages="errors['address_line_2']"
    ></v-text-field>

    <v-text-field
      label="City/Region"
      v-model="fields.city_region"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('city_region')"
      :error-messages="errors['city_region']"
    ></v-text-field>
    <v-text-field
      label="Postcode/Zip Code"
      v-model="fields.postcode"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('postcode')"
      :error-messages="errors['postcode']"
    ></v-text-field>
    <v-text-field
      label="Country"
      v-model="fields.country"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('country')"
      :error-messages="errors['country']"
    ></v-text-field>

    <v-text-field
      label="Phone Number"
      v-model="fields.phone"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('phone')"
      :error-messages="errors['phone']"
    ></v-text-field>

    <v-text-field
      label="Email Address"
      v-model="fields.email"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('email')"
      :error-messages="errors['email']"
    ></v-text-field>

    <v-text-field
      label="Job Title"
      v-model="fields.job_title"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('job_title')"
      :error-messages="errors['job_title']"
    ></v-text-field>

    <v-text-field
      label="Start date of employment"
      v-model="fields.start_date_of_employment"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('start_date_of_employment')"
      :error-messages="errors['start_date_of_employment']"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        company_name: null,
        contact_name: null,
        address_line_1: null,
        address_line_2: null,
        city_region: null,
        postcode: null,
        country: null,
        phone: null,
        email: null,
        job_title: null,
        start_date_of_employment: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.form !== null) {
      this.fields.company_name = this.form.employment.employment.company_name;
      this.fields.contact_name = this.form.employment.employment.contact_name;
      this.fields.address_line_1 =
        this.form.employment.employment.address_line_1;
      this.fields.address_line_2 =
        this.form.employment.employment.address_line_2;

      this.fields.city_region = this.form.employment.employment.city_region;
      this.fields.postcode = this.form.employment.employment.postcode;
      this.fields.country = this.form.employment.employment.country;
      this.fields.phone = this.form.employment.employment.phone;
      this.fields.email = this.form.employment.employment.email;

      this.fields.job_title = this.form.employment.employment.job_title;
      this.fields.start_date_of_employment =
        this.form.employment.employment.start_date_of_employment;
    }
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
