<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Employment</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="product-form">
          <v-select
            label="Employment Status*"
            :items="types"
            item-value="name"
            item-text="label"
            v-model="fields.professional_employment_status"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('professional_employment_status')"
            :error-messages="errors['professional_employment_status']"
          ></v-select>

          <SelfForm
            ref="self"
            :form="form"
            v-if="fields.professional_employment_status === 'self'"
          />
          <UniversalCredits
            ref="universalCredits"
            :form="form"
            v-if="fields.professional_employment_status === 'universal'"
          />

          <Employers
            ref="employers"
            :form="form"
            v-if="fields.professional_employment_status === 'employers'"
          />
        </v-form>
        <v-overlay absolute v-if="productLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="product-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelfForm from "./employer/SelfForm.vue";
import UniversalCredits from "./employer/UniversalCredits.vue";
import Employers from "./employer/Employers.vue";

export default {
  components: {
    SelfForm,
    UniversalCredits,
    Employers,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      errors: {},
      isEditing: false,
      fields: {
        form_title: "employer",
        professional_employment_status: null,
      },
      types: [
        { label: "Full time / Part-time employment", name: "employers" },
        { label: "Self Employed", name: "self" },
        { label: "Savings / Own Means / Pension", name: "saving" },
        { label: "Universal Credits/ Benefit Payments", name: "universal" },
      ],
    };
  },

  methods: {
    open(form = null) {
      if (form !== null) {
        this.form = form;
        this.fields.professional_employment_status =
          form.professional_employment_status;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let fields1 = ((type) => {
        switch (type) {
          case "self":
            return this.$refs.self.saveForm();
          case "employers":
            return this.$refs.employers.saveForm();
          case "universal":
            return this.$refs.universalCredits.saveForm();
        }
      })(this.fields.professional_employment_status);

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          ...fields1,
          ...this.fields,
        },
      };

      if (this.isEditing) {
        payload.formId = this.form.application_id;
      }

      this.$store
        .dispatch("easylets/applicationformsStore/saveForm", payload)
        .then(() => {
          this.resetForm();

          if (payload.fields.professional_employment_status === "self") {
            this.$refs.self.setErrors();
          } else if (
            payload.fields.professional_employment_status === "employers"
          ) {
            this.$refs.employers.setErrors();
          } else if (
            payload.fields.professional_employment_status === "universal"
          ) {
            this.$refs.universalCredits.setErrors();
          }
        })
        .catch((err) => {
          if (payload.fields.professional_employment_status === "self") {
            this.$refs.self.setErrors(err.response.data.errors);
          } else if (
            payload.fields.professional_employment_status === "employers"
          ) {
            this.$refs.employers.setErrors(err.response.data.errors);
          } else if (
            payload.fields.professional_employment_status === "universal"
          ) {
            this.$refs.universalCredits.setErrors(err.response.data.errors);
          }

          this.loading = false;
        });
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields = {
        form_title: "employer",
        professional_employment_status: null,
      };
    },
  },
};
</script>
