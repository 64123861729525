<template>
  <div>
    <v-text-field
      label="Type of benefit payments received"
      v-model="fields.type_of_benefit"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('type_of_benefit')"
      :error-messages="errors['type_of_benefit']"
    ></v-text-field>

    <v-text-field
      label="Length of time in receipt of current benefit payments"
      v-model="fields.length_current_benefit"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('length_current_benefit')"
      :error-messages="errors['length_current_benefit']"
    ></v-text-field>

    <v-text-field
      label="Have you futher benefit payments you expect to receive that you do not already"
      v-model="fields.futher_benefit"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('futher_benefit')"
      :error-messages="errors['futher_benefit']"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        type_of_benefit: null,
        length_current_benefit: null,
        futher_benefit: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.form !== null) {
      this.fields.type_of_benefit =
        this.form.employment.employment.type_of_benefit;
      this.fields.length_current_benefit =
        this.form.employment.employment.length_current_benefit;
      this.fields.futher_benefit =
        this.form.employment.employment.futher_benefit;
    }
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
