<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Additional Info</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.employmentDialog.open(form)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Customer's Information</span>
        </v-tooltip>
        <!--<v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.addressDialog.open(property.address)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Address</span>
        </v-tooltip>-->
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table v-if="form.professional_employment_status == 'saving'">
          <tbody>
            <tr>
              <th>Your Employment Status:</th>
              <td>Savings / Own Means / Pension</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="form.professional_employment_status == 'self'">
          <tbody>
            <tr>
              <th>Your Employment Status:</th>
              <td>Self Employed</td>
            </tr>
            <tr>
              <th>Number of years self employed</th>
              <td>
                {{ form.employment.employment.number_of_years_self_employed }}
              </td>
            </tr>
            <tr>
              <th>Trading name (if not a sole trader)</th>
              <td>
                {{ form.employment.employment.trading_name }}
              </td>
            </tr>
            <tr>
              <th>Last Financial years declared profit (if applicable)</th>
              <td>
                {{ form.employment.employment.last_financial_profit }}
              </td>
            </tr>
            <tr>
              <th>Current financial years expected profit</th>
              <td>
                {{ form.employment.employment.current_financial_profit }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="form.professional_employment_status == 'universal'"
        >
          <tbody>
            <tr>
              <th>Your Employment Status:</th>
              <td>Universal Credits/ Benefit Payments</td>
            </tr>
            <tr>
              <th>Type of benefit payments received</th>
              <td>
                {{ form.employment.employment.type_of_benefit }}
              </td>
            </tr>
            <tr>
              <th>Length of time in receipt of current benefit payments</th>
              <td>
                {{ form.employment.employment.length_current_benefit }}
              </td>
            </tr>
            <tr>
              <th>
                Have you futher benefit payments you expect to receive that you
                do not already
              </th>
              <td>
                {{ form.employment.employment.futher_benefit }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="form.professional_employment_status == 'employers'"
        >
          <tbody>
            <tr>
              <th>Your Employment Status:</th>
              <td>Full time / Part-time employment</td>
            </tr>
            <tr>
              <th>Company Name</th>
              <td>
                {{ form.employment.employment.company_name }}
              </td>
            </tr>
            <tr>
              <th>Contact Name</th>
              <td>
                {{ form.employment.employment.contact_name }}
              </td>
            </tr>
            <tr>
              <th>Address Line 1</th>
              <td>
                {{ form.employment.employment.address_line_1 }}
              </td>
            </tr>
            <tr>
              <th>Address Line 2</th>
              <td>
                {{ form.employment.employment.address_line_2 }}
              </td>
            </tr>
            <tr>
              <th>City/Region</th>
              <td>
                {{ form.employment.employment.city_region }}
              </td>
            </tr>
            <tr>
              <th>Postcode/Zip Code</th>
              <td>
                {{ form.employment.employment.postcode }}
              </td>
            </tr>
            <tr>
              <th>Country</th>
              <td>
                {{ form.employment.employment.country }}
              </td>
            </tr>
            <tr>
              <th>Phone Number</th>
              <td>
                {{ form.employment.employment.phone }}
              </td>
            </tr>
            <tr>
              <th>Email Address</th>
              <td>
                {{ form.employment.employment.email }}
              </td>
            </tr>
            <tr>
              <th>Job Title</th>
              <td>
                {{ form.employment.employment.job_title }}
              </td>
            </tr>
            <tr>
              <th>Start date of employment</th>
              <td>
                {{ form.employment.employment.start_date_of_employment }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <employment-dialog ref="employmentDialog" />
  </div>
</template>

<script>
import EmploymentDialog from "./components/EmploymentDialog.vue";

export default {
  components: {
    EmploymentDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
